import React from 'react';
import { Servers } from './components/Servers';
import './css/App.css';
import './scss/index.scss';

export class App extends React.Component<any, any> {
    public constructor(props: any) {
        super(props);
    }

    public render() {
        return (
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-2' />
                    <div className='col-lg-8'>
                        <h1 className="text-center my-md-3">WWW Wake on LAN</h1>
                        <Servers />
                    </div>
                    <div className='col-lg-2' />
                </div>
            </div>
        );
    }
}

export default App;