import { HubConnection, LogLevel, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { PingReply } from '../services/models/PingReply';

export class PingServersHub {
    private _connection: HubConnection;
    private _pingReplyCallback: any;

    constructor(pingReplyCallback: any) {
        this._connection = new HubConnectionBuilder()
            .withUrl('hubs/pingservers')
            .configureLogging(LogLevel.Warning)
            .withAutomaticReconnect()
            .build();
        try {
            this._connection.start();
        }
        catch (err) {
            console.error(err);
        }

        this._pingReplyCallback = pingReplyCallback;
        this._connection.on('PingReplyReceived', (pingReply: PingReply) => {
            this._pingReplyCallback(pingReply);
        });
    }

    pingServers() {
        // invoke method on server
        try {
            if (this._connection.state === HubConnectionState.Connected)
                this._connection.invoke('SendPing');
        }
        catch (err) {
            console.error(err);
        }
    }
}