import * as React from 'react';
import 'isomorphic-fetch';
import { Badge, Button } from 'react-bootstrap';
import imagePC from '../images/pc.png';

interface IServerPingState {
    wakeupButtonDisabled: boolean;
    wakeupButtonLoading: boolean;
}

export class ServerRow extends React.Component<any, IServerPingState> {
    constructor(props: any) {
        super(props);
        this.state = { wakeupButtonDisabled: false, wakeupButtonLoading: false };
    }

    public handleWakeUpOnClick(event: any) : void {
        this.setState({ wakeupButtonDisabled: true, wakeupButtonLoading: true });

        fetch(`api/servers/wakeup/${this.props.id}`, { method: "POST" })
            .then(response => {
                this.setState({ wakeupButtonDisabled: false, wakeupButtonLoading: false });
                if (response.status !== 200) {
                    console.error(`API wakeup return status ${response.status} for ServerRow id=${this.props.id}`);
                }
            });
    }

    public render() {
        let statusLabel: JSX.Element;
        let statusText: string;

        if (this.props.roundtripTime == null) {
            statusLabel = <div />;
            statusText = "Loading...";
        } else {
            if (this.props.roundtripTime === -1) {
                statusLabel = <Badge variant="danger">Offline</Badge>;
                statusText = "No reply from machine";
            } else {
                statusLabel = <Badge variant="success">Online</Badge>;
                statusText = `Reply from machine time=${this.props.roundtripTime}`;
            }
        }

        return (
            <tr>
                <td className="col-xs-1 align-middle"><img src={imagePC} alt="PC" height="48" width="48" /></td>
                <td className="col-xs-3 align-middle text-white">{this.props.name}</td>
                <td className="col-xs-6 align-middle text-white">{statusLabel} {statusText}</td>
                <td className="col-xs-2 align-middle">
                    <Button className="btn btn-primary btn-lg" disabled={(this.props.roundtripTime == null || this.props.roundtripTime !== -1 || this.state.wakeupButtonDisabled)} onClick={(e: any) => this.handleWakeUpOnClick(e)}>
                        <i className={this.state.wakeupButtonLoading ? "fa fa-spinner fa-spin" : ""}></i>
                        {this.state.wakeupButtonLoading ? ' Waking' : " Wake Up"}
                    </Button>
                </td>
            </tr>
        );
    }
}

export default ServerRow;