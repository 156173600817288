import * as React from 'react';
import 'isomorphic-fetch';
import { ServerRow } from './ServerRow';
import { PingServersHub } from '../services/PingServersHub';
import { PingReply } from '../services/models/PingReply';

interface IServersInfo {
    id: number;
    name: string;
    roundtripTime: number;
}

interface IServersInfoState {
    serversInfo: IServersInfo[];
    loadingServers: boolean;
}

export class Servers extends React.Component<any, IServersInfoState> {
    private timer: any;
    private pingServersHub: PingServersHub;

    constructor(props: any) {
        super(props);
        this.state = { serversInfo: [], loadingServers: true };

        fetch('api/servers')
            .then(response => response.json() as Promise<IServersInfo[]>)
            .then(data => {
                this.setState({ serversInfo: data, loadingServers: false });
            });

        this.pingServersHub = new PingServersHub((pingReply: PingReply) => {
            const updatedServersInfo = this.state.serversInfo.map(item => {
                if (item.id === pingReply.serverid) {
                    item.roundtripTime = pingReply.pingresponse;
                    return item;
                } else {
                    return item;
                }
            });

            this.setState({ serversInfo: updatedServersInfo });
        });
    }

    public componentDidMount() {
        this.pingServersHub.pingServers();
        this.timer = setInterval(() => this.pingServersHub.pingServers(), 2000);
    }

    public render() {
        return (
            <div>
                {this.state.loadingServers ? <p><em>Loading...</em></p> : Servers.renderServersTable(this.state.serversInfo) }
            </div>
        );
    }

    private static renderServersTable(servers: IServersInfo[]) {
        return (
            <table className="table table-striped table-hover">
                    <tbody>
                    {servers.map(serversInfo =>
                        <ServerRow key={serversInfo.id} id={serversInfo.id} name={serversInfo.name} roundtripTime={serversInfo.roundtripTime} />
                    )}
                    </tbody>
                </table>
            );
    }
}

export default Servers;